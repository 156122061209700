import React, { useState } from "react"
import addToMailchimp from 'gatsby-plugin-mailchimp'

function Subscribe() {
    const [email, setEmail] = useState(
        ''
    );
    const [result, setResult] = useState();
    const handleChange = event => setEmail(event.target.value);
    // const submitBtn = event => console.log(email);
    const handleSubmit = async (e) => {
        e.preventDefault();
        const r = await addToMailchimp(email, { FNAME: 'PAAPA' })
        setResult(r)
    }
    if (result !== undefined && result.result === "success") {
        return (
            <div>
                <p>{result.msg}</p>
            </div>
        )
    }
    return (
        <form onSubmit={handleSubmit}>

            <div>
                <Input value={email} onChangeInput={handleChange}> Email: </Input>
                <input type="submit" value="Suscribirme!" />
                <MsgBottom value={result} />
            </div>
        </form>
    );
}
const MsgBottom = ({ value }) => {
    if (value) {
        let res = value.msg.split('. ')[0]
        return (
            <p>{res}</p>
        );
    } else {
        return (<p></p>)
    }
}

const Input = ({ value, onChangeInput, children }) => (
    <label>
        {children}
        <input type="text" value={value} onChange={onChangeInput} />
    </label>
);

export default Subscribe;